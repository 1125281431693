import Link from 'next/link'
import React, { FC, ReactNode } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IBrandLogoItem } from 'lib/actions'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'
export interface IBrandLogoListProps {
  actionButton?: {
    children?: ReactNode
    url?: string
  }
  className?: string
  componentId?: string
  brandImagesData?: IBrandLogoItem[]
  header?: string
  headerClassName?: string
}

export const BrandLogoList: FC<IBrandLogoListProps> = ({
  actionButton,
  className,
  componentId,
  brandImagesData,
  header,
  headerClassName,
  ...restProps
}: IBrandLogoListProps) => {
  const BrandLogoListClasses = CN(
    `${componentId}-brand-logo-list gap-[24px] flex flex-col`,
    { 'lg:py-[24px]': !actionButton },
    className
  )

  const {
    originalUrl: actionOriginalUrl,
    restUrlWithQuery: actionRestUrlWithQuery,
    isInsideUrl: actionIsInsideUrl
  } = checkURL(actionButton?.url ?? '')

  if (brandImagesData?.length) {
    return (
      <div className={BrandLogoListClasses} {...restProps}>
        <div className='flex flex-col justify-start gap-y-2 gap-x-6 lg:!flex-row lg:!justify-between lg:items-end'>
          {header && (
            <div
              data-component-id='popular-brand-category-header'
              className={CN('font-600 text-h3 text-N-800', headerClassName)}>
              {header}
            </div>
          )}
          {actionButton?.children && (
            <Link
              href={actionIsInsideUrl ? actionRestUrlWithQuery : actionOriginalUrl ?? {}}
              passHref
              target={actionIsInsideUrl ? '' : '_blank'}>
              <Button
                componentId={`${componentId}-action-button`}
                className='w-fit'
                appearance='link'
                size='md'
                iconAfter='nzsbi-chevron-right'
                {...actionButton}>
                {actionButton?.children}
              </Button>
            </Link>
          )}
        </div>

        <div
          className={CN('grid grid-cols-2 lg:grid-cols-6', {
            'sm:grid-cols-4 gap-[16px] sm:gap-[20px] lg:!gap-x-[32px] lg:gap-y-[16px]':
              actionButton,
            'sm:grid-cols-3  gap-x-[16px] gap-y-[32px] sm:gap-[20px] lg:gap-x-[32px] lg:!gap-y-[16px]':
              !actionButton
          })}>
          {brandImagesData?.map((item: IBrandLogoItem, index: any) => {
            let url: any
            let isInside: any
            const linkToCuration = item?.attributes?.LinkToSearchCuration

            if (item?.attributes?.LinkToSearchCuration) {
              const urlProps = checkURL(item?.attributes?.SearchCurationURL)
              isInside = urlProps?.isInsideUrl
              url = urlProps?.originalUrl
            } else {
              url = item?.attributes?.SearchCurationURL
              isInside = true
            }
            return (
              <Link
                passHref
                target={isInside ? '' : '_blank'}
                href={
                  linkToCuration
                    ? url
                    : `/brands/shop-by-brands/${JSON.parse(item?.attributes?.Brand)?.value}`
                }
                key={item?.attributes?.BrandID}
                className={CN({
                  'pointer-events-none': !(url || JSON.parse(item?.attributes?.Brand)?.value)
                })}>
                <div className='border border-N-100 rounded-s flex px-[24px] py-[8px] max-w-[190px] md:max-w-[208px]  md:h-[96px] h-[96px] relative'>
                  <NextImage
                    nextImgStyle={{ padding: '8px 24px', objectFit: 'contain' }}
                    data-component-id={`${componentId}-brand-banner-image`}
                    imgKey={`${item?.attributes?.BrandID ?? ''}-${index}`}
                    imageUrl={
                      item?.attributes?.BrandLogo?.data?.attributes?.provider_metadata?.public_id ||
                      ''
                    }
                    system='CMS'
                    useWithDimensions={false}
                    alt={
                      item?.attributes?.BrandLogo?.data?.attributes?.alternativeText ||
                      item.attributes.BrandName ||
                      ''
                    }
                    {...restProps}
                  />
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
}

export default BrandLogoList
